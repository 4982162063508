<template>
  <el-card>
    <el-row>
      <el-date-picker
        class="vg_ml_8 vg_mr_8 topDateRangeSearch"
        size="small"
        v-model="dateRange"
        type="daterange"
        range-separator="至"
        start-placeholder="录入开始日期"
        end-placeholder="录入结束日期"
      />
      <el-button style="margin-bottom: 10px" type="info" size="small" class="vd_export" @click="buttonRefresh()"> 刷新</el-button>
      <el-button style="margin-bottom: 10px" type="primary" size="small" class="vd_export" @click="getFequPushList()"> 查询</el-button>
      <el-button style="margin-bottom: 10px" type="success" size="small" class="vd_export" @click="haveKnown()"> 已知晓</el-button>
    </el-row>
    <el-row>
      <el-col :md="24">
        <el-table ref="multiTable" :data="tableData" border v-loading="loading" class="vg_cursor" @selection-change="handleSelectionChange">
          <el-table-column type="selection" width="48" align="center"></el-table-column>
          <el-table-column type="index" label="序号" align="center" width="50px" />
          <el-table-column label="接收时间" prop="fequ_push_time" width="150px" :formatter="formatDate" />
          <el-table-column label="工厂打样单号" width="150px" prop="fequ_no" />
          <el-table-column label="委托打样单号" width="150px" prop="requ_no" />
          <el-table-column label="接收货号" width="auto" prop="fequ_push_text" show-overflow-tooltip />
          <el-table-column label="打样工厂" prop="supp_abbr" show-overflow-tooltip width="auto" />
        </el-table>
      </el-col>
    </el-row>
  </el-card>
</template>

<script>
import { get } from '@api/request';
import { fequAPI } from '@api/modules/fequ';
import bus from '@/components/common/bus';
import { getArrayIds } from '@assets/js/arrayUtils';

export default {
  name: 'push9009',
  data() {
    return {
      tableData: [],
      loading: true,
      dateRange: [],
      selectionsList: []
    };
  },
  created() {
    this.initData();
  },
  methods: {
    initData() {
      this.getFequPushList();
    },
    handleSelectionChange(selection) {
      this.selectionsList = selection;
    },
    //获取list
    getFequPushList() {
      this.loading = true;
      let [startTime, endTime] = this.dateRange || [];
      let start_time = startTime?.getTime();
      let end_time = endTime?.getTime();
      get(fequAPI.getfequPush9009List, { stff_id: this.$cookies.get('userInfo').stff_id, start_time: start_time, end_time: end_time })
        .then(res => {
          if (res.data.code === 0) {
            this.tableData = res.data.data.list;
            setTimeout(() => {
              this.loading = false;
            }, 200);
            return;
          }
          this.$message.error(res.data.msg);
        })
        .catch(res => {
          this.$message.error(res.data.msg);
        });
    },
    //已知晓
    haveKnown() {
      if (this.selectionsList.length === 0) {
        return this.$message.info('至少选择一条信息');
      }
      let fequtakepush_ids = getArrayIds(this.selectionsList, 'fequtakepush_id').toString();
      get(fequAPI.haveKnown, { fequtakepush_ids: fequtakepush_ids })
        .then(res => {
          if (res.data.code === 0) {
            this.$message.success(res.data.msg);
          } else {
            this.$message.error(res.data.msg);
          }
          this.initData();
          //刷新首页推送提醒数量
          bus.$emit('getPermInfo');
        })
        .catch(res => {
          this.$message.error(res.data.msg);
        });
    },
    //刷新
    buttonRefresh() {
      this.dateRange = [];
      this.initData();
    },
    // 时间转换
    formatDate(row) {
      if (row.fequ_push_time) {
        return this.helper.toTime13(row.fequ_push_time);
      } else {
        return '暂无';
      }
    }
  }
};
</script>

<style scoped lang="scss">
.item {
  max-width: 1500px !important; /* 根据需要设置 */
}

::v-deep .el-tooltip__popper {
  max-width: 1500px;
}
</style>
